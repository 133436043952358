import React, { useState } from 'react'

const WhatIsPercentOf = () => {

    const [firstNumber, setFirstNumber] = useState(null)
    const [secondNumber, setSecondNumber] = useState(null)
    const [value, setValue] = useState(null)

    const calculate = e => {
		e.preventDefault()
		const value = (parseFloat(firstNumber) * parseFloat(secondNumber)) / 100
        setValue(value)
	}
	
	const inputStyle = {
		maxWidth: 120,
		verticalAlign: `middle`
	}

    return (
		<form className="" onSubmit={calculate}>
			<div className="columns is-tablet">
				<div className="column is-two-thirds">
					<span>What is {` `}</span>
					<input
						className="input"
						type="text"
						placeholder="1"
						value={firstNumber || ``}
						onChange={e => setFirstNumber(e.target.value)}
						style={inputStyle}
						placeholder="8"
					/> 
					<span>{` `} % of {` `}</span>
					<input
						className="input"
						type="text"
						value={secondNumber || ``}
						onChange={e => setSecondNumber(e.target.value)}
						style={inputStyle}
						placeholder="200"
					/>
					<span>{` `}?</span>
				</div>
				<div className="column">
					<div className="field has-addons">
						<div className="control">
							<button type="submit" className="button is-primary">Calculate</button>
						</div>
						<div className="control">
							<input
								className="input is-primary"
								type="text"
								value={value || ``}
								style={{ ...inputStyle, maxWidth: `none`, fontWeight: 900 }}
								placeholder={`16`}
								readOnly={true}
							/>
						</div>
					</div>
				</div>
			</div>
		</form>
	)
}

WhatIsPercentOf.propTypes = {}

WhatIsPercentOf.defaultProps = {}

export default WhatIsPercentOf
