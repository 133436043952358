import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import IsWhatPercentOf from '../../tools/IsWhatPercentOf'
import WhatIsPercentOf from '../../tools/WhatIsPercentOf'
import IncreaseDecreaseCalculator from '../../tools/IncreaseDecreaseCalculator'

const PercentageCalculatorPage = () => (
    <Layout>
        <SEO title="Percentage Calculator – Simple Online Percentage Calculation Tool" />

        <section className="hero is-primary">
            <div className="hero-body">
                <div className="container is-fluid">
                    <h1 className="title">Online Percentage Calculator</h1>
                    <h2 className="subtitle">
                        Simple tool to calculate various percentage values in multiple directions. What is the percentage value of anothe value? Or, what percentage is a value compared to another? Finally, calculate percentage increases and decreases.
                    </h2>
                </div>
            </div>
        </section>

		<section className="section">
			<div className="container">
				<h1 className="title">X is what percent of Y?</h1>
				<IsWhatPercentOf />
				<div className="panel">
					<p className="panel-heading">Formula</p>
					<p className="panel-block">
						The formula to know what percentage a value represents compared to another is as follows:
					</p>
					<pre className="panel-block" style={{ fontSize: `1em` }}>
						percentage = first number / second number * 100
					</pre>
					<p className="panel-block">
						Divide the first value by the second value, and multiply that result by 100 to get the percentage.
					</p>
				</div>
			</div>
		</section>

		<section className="section">
			<div className="container">
				<h1 className="title">What is X% of Y?</h1>
				<WhatIsPercentOf />
				<div className="panel">
					<p className="panel-heading">Formula</p>
					<p className="panel-block">
						The formula to know what the percentage of a value represents is as follows:
					</p>
					<pre className="panel-block" style={{ fontSize: `1em` }}>
						value = first number * second number / 100
					</pre>
					<p className="panel-block">
						Multiply the first value by the second value, and divide that result by 100 to get the actual value.
					</p>
				</div>
			</div>
		</section>

		<section className="section">
			<div className="container">
				<h1 className="title">What is the percentage increase or decreease?</h1>
				<IncreaseDecreaseCalculator />
				<div className="panel">
					<p className="panel-heading">Formula</p>
					<p className="panel-block">
						The formula to know what the percentage increase or decrease betweeen two numbers is as follows:
					</p>
					<pre className="panel-block" style={{ fontSize: `1em` }}>
						difference = first number - second number<br />
						percentage = difference / first number * 100
					</pre>
					<p className="panel-block">
						Subtract your first number with your second to get the difference between both numbers. Then, divide that difference by the first number, and multiply by 100 to get the percentage increase, or decrease if negative.
					</p>
				</div>
			</div>
		</section>

    </Layout>
)

export default PercentageCalculatorPage
