import React, { useState } from 'react'

const IncreaseDecreaseCalculator = ({ outputPercentSymbol=true }) => {

    const [firstNumber, setFirstNumber] = useState(null)
    const [secondNumber, setSecondNumber] = useState(null)
    const [difference, setDifference] = useState(null)
    const [percent, setPercent] = useState(null)

    const calculate = e => {
		e.preventDefault()
		const diff = parseFloat(secondNumber) - parseFloat(firstNumber)
		const pc = diff / firstNumber * 100
        setDifference(diff)
		setPercent(pc)
		console.log({
			firstNumber,
			secondNumber,
			diff,
			pc
		})
	}
	
	const inputStyle = {
		maxWidth: 120,
		verticalAlign: `middle`
	}

    return (
		<form className="" onSubmit={calculate}>
			<div className="columns is-tablet">
				<div className="column is-two-thirds">
					<span>What is the increase/decrease from {` `}</span>
					<input
						className="input"
						type="text"
						placeholder="1"
						value={firstNumber || ``}
						onChange={e => setFirstNumber(e.target.value)}
						style={inputStyle}
						placeholder="50"
					/> 
					<span>{` `} to {` `}</span>
					<input
						className="input"
						type="text"
						value={secondNumber || ``}
						onChange={e => setSecondNumber(e.target.value)}
						style={inputStyle}
						placeholder="150"
					/>
					<span>{` `}?</span>
				</div>
				<div className="column">
					<div className="field has-addons">
						<div className="control">
							<button type="submit" className="button is-primary">Calculate</button>
						</div>
						<div className="control">
							<input
								className="input is-primary"
								type="text"
								value={percent ? `${percent.toLocaleString()}${outputPercentSymbol ? `%` : ``}` : ``}
								style={{ ...inputStyle, maxWidth: `none`, fontWeight: 900 }}
								placeholder={`200${outputPercentSymbol ? `%` : ``}`}
								readOnly={true}
							/>
						</div>
						<div className="control">
							<input
								className="input is-primary"
								type="text"
								value={difference ? `${difference > 0 ? `+` : ``}${difference.toLocaleString()}` : ``}
								style={{ ...inputStyle, maxWidth: `none`, fontWeight: 100 }}
								placeholder={`+100`}
								readOnly={true}
							/>
						</div>
					</div>
				</div>
			</div>
		</form>
	)
}

IncreaseDecreaseCalculator.propTypes = {}

IncreaseDecreaseCalculator.defaultProps = {}

export default IncreaseDecreaseCalculator
