import React, { useState } from 'react'

const IsWhatPercentOf = ({ outputPercentSymbol=true }) => {

    const [firstNumber, setFirstNumber] = useState(null)
    const [secondNumber, setSecondNumber] = useState(null)
    const [percent, setPercent] = useState(null)

    const calculate = e => {
		e.preventDefault()
		const value = parseFloat(firstNumber) / parseFloat(secondNumber)
        setPercent(value)
	}
	
	const inputStyle = {
		maxWidth: 120,
		verticalAlign: `middle`
	}

    return (
		<form className="" onSubmit={calculate}>
			<div className="IsWhatPercentOf-inline-form">
				<div className="columns is-tablet">
					<div className="column is-two-thirds">
						<input
							className="input"
							type="text"
							placeholder="1"
							value={firstNumber || ``}
							onChange={e => setFirstNumber(e.target.value)}
							style={inputStyle}
							placeholder="25"
						/> 
						<span>{` `} is what percent of {` `}</span>
						<input
							className="input"
							type="text"
							value={secondNumber || ``}
							onChange={e => setSecondNumber(e.target.value)}
							style={inputStyle}
							placeholder="125"
						/>
						<span>{` `}?</span>
					</div>
					<div className="column">
						<div className="field has-addons">
							<div className="control">
								<button type="submit" className="button is-primary">Calculate</button>
							</div>
							<div className="control">
								<input
									className="input is-primary"
									type="text"
									value={percent ? `${percent*100}${outputPercentSymbol ? `%` : ``}` : `` }
									style={{ ...inputStyle, maxWidth: `none`, fontWeight: 900 }}
									placeholder={`20${outputPercentSymbol ? `%` : ``}`}
									readOnly={true}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	)
}

IsWhatPercentOf.propTypes = {}

IsWhatPercentOf.defaultProps = {}

export default IsWhatPercentOf
